import { useMutation } from '@tanstack/vue-query';

const { logout } = useAuth();

export function useDeleteActiveSessions() {
  return useMutation({
    mutationFn: async () => {
      const { error } = await useApi(
                `/api/v2/auth/active-sessions`,
                {
                  method: 'DELETE',
                },
      );
      if (error.value)
        throw error.value;
    },
    onSuccess() {
      logout();
    },
  });
}
